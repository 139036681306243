import { bootstrapApplication } from '@angular/platform-browser';
import { enableProdMode } from '@angular/core';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import * as Parse from 'parse';

if (environment.production) {
  enableProdMode();
}

Parse.initialize(environment.appId);
(Parse as any).serverURL = environment.serverUrl;
(Parse as any).idempotency = true;

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
