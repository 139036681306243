import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import * as Parse from 'parse';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MatDialogModule,
    MatSnackBarModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private _router: Router,
    iconRegistry: MatIconRegistry
  ) {
    iconRegistry.setDefaultFontSetClass('material-icons-outlined');
  }
  
  ngOnInit(): void {
    this.loadUser();
  }

  async loadUser(): Promise<void>{
    const user = Parse.User.current();
    if (user) {
      try {
        await user.fetch();
      } catch (error: any) {
        if (error.code === 209) {
          this._onLogOut();
        }
      }
    }
  }

  private async _onLogOut(): Promise<void> {
    try {
      await Parse.User.logOut();
    } catch (err: any) {
      if (err.code === 209) {
        this._router.navigate(['/login']);
      }
    }
    this._router.navigate(['/login']);
  }
}
