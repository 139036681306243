// Vendor Development
export const environment = {
  production: false,
  serverUrl: 'https://api-dev.syzl.io/api',
  graphQlUrl: 'https://api-dev.syzl.io/graphql',
  appId: '6JMrpjIj8L',
  languages: [{
    id: 'en',
    locale: 'en-US',
    default: true,
    label: 'English',
  }],
  pageSize: 25,
  firebase: {
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  firebaseConfig: {
    apiKey: "AIzaSyDyv4MbjwrZJxQCf_L0YiXM9ElfPbfDm8A",
    authDomain: "syzl-dev.firebaseapp.com",
    projectId: "syzl-dev",
    storageBucket: "syzl-dev.appspot.com",
    messagingSenderId: "222858438652",
    appId: "1:222858438652:web:d4e673a336f1b805483479",
    measurementId: "G-K3KZQTMTGN"
  },
};